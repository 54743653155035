<template>
  <div ref="demoVideo" class="demo-video text-center py-16">
    <div v-if="canDemo">
      <!-- <div class="section-title my-6">
        {{ $t('landing.demo.headline') }}
      </div>
      <div class="section-title my-8 px-5">
        {{ $t('landing.demo.subTitle') }}
      </div> -->
      <div>
        <div class="text-center">
          <!-- <div :class="selectOpt === `iphone` ? `tabcontent-active` : `tabcontent`">
            <div class="in-demo mt-5">
              <v-tooltip
                bottom
                color="#45dffd"
                content-class="tooltip"
                max-width="300px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    class="text-xl"
                    v-on="on"
                  >
                    <span class="font-weight-bold">1.</span> {{ $t('landing.demoLanding.stepOne.ios.open') }}
                    <span class="font-weight-bold">Whatsapp</span>
                    {{ $t('landing.demoLanding.stepOne.ios.desc') }}</span>
                </template>
                <span>{{ $t('landing.demoLanding.stepOne.ios.moreOpt') }}
                  <span class="font-weight-bold">{{ $t('landing.demoLanding.stepOne.ios.moreOpt2') }}</span></span>
              </v-tooltip>
            </div>
            <div class="mt-3 px-5 in-demo">
              <v-tooltip
                bottom
                color="#45dffd"
                content-class="tooltip"
                max-width="300px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    class="text-xl"
                    v-on="on"
                  >
                    <span>
                      2. {{ $t('landing.demoLanding.stepTwo.android.tap') }}
                      <span class="font-weight-bold">{{ $t('landing.demoLanding.stepTwo.android.linkDevice') }}</span>.
                    </span></span>
                </template>
                <span>
                  {{ $t('landing.demoLanding.stepTwo.android.tap') }}
                  <span class="font-weight-bold">{{ $t('landing.demoLanding.stepTwo.android.linkDevice') }}</span> >
                  <span class="font-weight-bold">OK</span>. {{ $t('landing.demoLanding.stepTwo.ios.desc') }}</span>
              </v-tooltip>
            </div>
            <div class="mt-3 px-5 in-demo">
              <v-tooltip
                bottom
                color="#45dffd"
                content-class="tooltip"
                max-width="300px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    class="text-xl"
                    v-on="on"
                  >
                    <span class="font-weight-bold">3.</span> {{ $t('landing.demoLanding.stepThree.android.select') }}
                    <span class="font-weight-bold">{{ $t('landing.demoLanding.stepThree.android.keepSigned') }}</span>
                  </span>
                </template>
                <span>{{ $t('landing.demoLanding.stepThree.android.desc.head') }}
                  <span class="font-weight-bold">{{ $t('landing.demoLanding.stepThree.android.keepSigned') }}</span>
                  {{ $t('landing.demoLanding.stepThree.android.desc.body') }}</span>
              </v-tooltip>
            </div>
            <div class="mt-3">
              <span class="text-xl">
                <span class="font-weight-bold">4.</span> {{ $t('landing.demoLanding.stepFour.android.scan') }}
                <span class="font-weight-bold">{{ $t('landing.demoLanding.stepFour.android.qrCode') }}</span>
                {{ $t('landing.demoLanding.stepFour.android.below') }}</span>
            </div>
          </div> -->
          <!-- <div class="mt-3 px-5 in-demo">
            <p class="text-sm">
              <span class="font-weight-bold">{{ $t('landing.demoLanding.note.headline') }}</span>{{ $t('landing.demoLanding.note.body') }}
              <a
                style="color: #8becff"
                href="https://faq.whatsapp.com/web/download-and-installation/how-to-join-or-leave-the-multi-device-beta/"
                target="_blank"
              >{{ $t('landing.demoLanding.note.link') }}</a>"
            </p>
          </div> -->
        </div>
      </div>
      <div class="section-title my-8">
        <div v-if="currentStatus !== 1">
          <iframe
            id="iframe-qr-code"
            :key="keyIframeQr"
            title="QR Code"
            style="border: 0"
            :src="iframeSrc"
            width="277"
            height="277"
          ></iframe>
        </div>
        <div v-else class="text-center justify-center">
          <img src="@/assets/images/phone_connected.png" />
        </div>
      </div>
      <div class="section-title my-8">
        <v-btn v-if="currentStatus === 1" elevation="2" x-large color="primary" @click="sendMessage">
          {{ $t('landing.hero.sendMessage') }}
          <v-icon class="ml-2">
            {{ icons.mdiSendOutline }}
          </v-icon>
        </v-btn>
      </div>

      <v-btn elevation="2" x-large class="button-goLogin mb-4" color="#2ED47A" @click="goToLogin">
        <span style="color: white">{{ $t('landing.hero.tryNow') }}</span>

        <v-icon color="white">
          {{ icons.mdiChevronRight }}
        </v-icon>
      </v-btn>
    </div>
    <div v-else>
      <!-- <div class="section-title my-6">
        {{ $t('landing.demo.headline') }}
      </div> -->
      <div class="section-title my-8">
        {{ $t('landing.demo.subTitlesuccess') }}
      </div>

      <v-btn elevation="2" x-large class="button-goLogin mb-4" color="#2ED47A" @click="goToLogin">
        <span style="color: white">{{ $t('landing.hero.tryNow') }}</span>

        <v-icon color="white">
          {{ icons.mdiChevronRight }}
        </v-icon>
      </v-btn>
    </div>

    <div v-if="canDemo">
      <span class="font-weight-bold">{{ $t('channels.howToUse') }}</span>
      <!-- migrasi -->
      <div class="text-center">
        <div class="in-demo mt-5">
          <v-tooltip bottom color="#45dffd" content-class="tooltip">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" class="text-xl" v-on="on">
                <span class="font-weight-bold">1.</span> {{ $t('landing.demoLanding.stepOne.android.open') }}
                <span class="font-weight-bold">Whatsapp </span
                >{{ $t('landing.demoLanding.stepOne.android.desc') }}</span
              >
            </template>
            <span> {{ $t('landing.demoLanding.stepOne.android.moreOpt') }}</span>
          </v-tooltip>
          <br />
        </div>
        <div class="mt-3 px-5 in-demo">
          <v-tooltip bottom color="#45dffd" content-class="tooltip" max-width="300px">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" class="text-xl" v-on="on">
                <span class="font-weight-bold">2.</span> {{ $t('landing.demoLanding.stepTwo.android.tap') }}
                <span class="font-weight-bold">{{ $t('landing.demoLanding.stepTwo.android.linkDevice') }}</span>
              </span>
            </template>
            <span>{{ $t('landing.demoLanding.stepTwo.android.desc') }}</span>
          </v-tooltip>
        </div>
        <div class="mt-3">
          <span class="text-xl">
            <span class="font-weight-bold">3.</span> {{ $t('landing.demoLanding.stepFour.android.scan') }}
            <span class="font-weight-bold">{{ $t('landing.demoLanding.stepFour.android.qrCode') }}</span>
            {{ $t('landing.demoLanding.stepFour.android.below') }}</span
          >
        </div>
      </div>
    </div>

    <v-dialog v-model="dialogAlert" width="500">
      <v-card>
        <v-card-title class="justify-center" style="word-break: break-word">
          {{ $t('demoMessageSuccess') }}
        </v-card-title>
        <v-card-text>
          <lottie-player
            src="https://assets8.lottiefiles.com/packages/lf20_c6lvuzhy.json"
            background="transparent"
            speed="1"
            class="lottie-image mx-auto"
            loop
            autoplay
          ></lottie-player>
        </v-card-text>
      </v-card>
    </v-dialog>
    <dialog-send-message-landing ref="dialogSendMessageLanding"></dialog-send-message-landing>
  </div>
</template>

<script>
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { mdiChevronRight, mdiSendOutline } from '@mdi/js'

import dialogSendMessageLanding from '../component/DialogSendMessageLanding.vue'

const fpPromise = FingerprintJS.load()

export default {
  components: {
    dialogSendMessageLanding,
  },
  setup() {
    return {
      icons: {
        mdiChevronRight,
        mdiSendOutline,
      },
    }
  },
  data: () => ({
    selectOpt: 'android',
    tech: 0,
    testi: 0,

    navbarShow: false,

    faqTitle: 'color: #222222',
    isActive: true,
    index: 6,
    opacity: 1,
    overlay: false,

    iframeSrc: '',
    visitorId: '',
    fingerprintId: '',
    currentStatus: 0,
    canDemo: true,
    dialogAlert: false,
    keyIframeQr: 0,
  }),
  computed: {
    howToUseOne() {
      return this.$t('channels.howToUseOne')
    },
    howToUseTwo() {
      return this.$t('channels.howToUseTwo')
    },
    howToUseThree() {
      return this.$t('channels.howToUseThree')
    },
    howToUseFour() {
      return this.$t('channels.howToUseFourBelow')
    },
  },
  async mounted() {
    if (this.$route.params.lang === 'id') {
      this.$root.$i18n.locale = 'id'
    } else {
      this.$root.$i18n.locale = 'en'
    }

    window.addEventListener('message', this.receiveMessage, false)

    const fp = await fpPromise
    const result = await fp.get()

    const { visitorId } = result

    this.visitorId = `demouser${this.$nanoid()}`
    this.fingerprintId = `${visitorId}`
    this.iframeSrc = `${process.env.VUE_APP_WA_SERVICES_URL}register-channel-pg/${this.visitorId}`
    //console.log(this.iframeSrc, ' == iframeSrc')
  },
  methods: {
    trackEventGA(value) {
      this.$gtag.event('scan_qr_whatsapp', {
        event_category: 'Scan QR WhatsApp Landing Page',
        event_label: 'Scan QR WhatsApp Landing Page',
        value,
      })
    },
    SelectOrder(order) {
      this.selectOpt = order
    },
    async receiveMessage(event) {
      //console.log(event, ' == event')
      const self = this

      if (
        event.origin === process.env.VUE_APP_WA_SERVICES_URL.substr(0, process.env.VUE_APP_WA_SERVICES_URL.length - 1)
      ) {
        //console.log(event, ' == event1')
        if (event.data.err) {
          //console.log(event.data.err)
        } else if (event.data.no && event.data.id) {
          self.trackEventGA(event.data.no)
          self.sendMessage()
        }
      }
    },
    sendMessage() {
      this.$refs.dialogSendMessageLanding.open(this.visitorId).then(async dialog => {
        if (dialog.status) {
          const response = await this.$store.dispatch('auth/sendMessage', {
            phone_number: dialog.phoneNumber,
            message: dialog.message,
            user_id: this.visitorId,
            fingerprint_id: this.fingerprintId,
          })
          if (response.data.status) {
            this.dialogAlert = true
            this.canDemo = false

            await this.$store.dispatch('auth/deleteInstance', this.visitorId)
          } else {
            await this.$store.dispatch('auth/deleteInstance', this.visitorId)
            this.visitorId = `demouser${this.$nanoid()}`
            this.iframeSrc = `${process.env.VUE_APP_WA_SERVICES_URL}register-channel-pg/${this.visitorId}`
          }
        } else {
          await this.$store.dispatch('auth/deleteInstance', this.visitorId)
          this.visitorId = `demouser${this.$nanoid()}`
          this.fingerprintId = `${this.visitorId}`
          this.iframeSrc = `${process.env.VUE_APP_WA_SERVICES_URL}register-channel-pg/${this.visitorId}`
          this.keyIframeQr += 1
        }
      })
    },
    goToLogin() {
      const route = this.$router.resolve({ name: 'login' })
      window.open(route.href, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

h2,
h3,
a {
  color: #5c5c5c;
}

a {
  text-decoration: none;
}

.v-btn {
  min-width: 0;
  padding: 200px;
}

.tech {
  margin: 0 -12px -12px;
  color: #222222;
}
.demo-video {
  background: #f15858;
  margin: -12px -12px -12px -12px;
  color: #fff;
}

.section-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
}

.how-it-works {
  margin: -12px 0px -12px;
}

.barcode {
  height: 277px;
  width: 277px;
}

.try-btn {
  text-align: start;
}
.in-demo {
  margin: auto;
  max-width: 400px;
}
/* Style the tab */
.tab-landing {
  margin: auto;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

/* Style the buttons that are used to open the tab-landing content */
.tab-landing button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
// .tab-landing button:hover {
//   color:
// #3fc19f;
// }
// migrasi
.tab-landing button:hover {
  color: #45dffd;
}

/* Create an active/current tab-landinglink class */
// .tab-landing button.active {
//   border-bottom: 4px solid #3fc19f;
// }
// migrasi
.tab-landing button.active {
  border-bottom: 4px solid #45dffd;
}
.v-tooltip__content {
  opacity: 1 !important;
}

/* Style the tab content */
.tabcontent {
  display: none;
}
.tabcontent-active {
  animation: fadeEffect 1s;
  display: block;
}

/* Go from zero to full opacity */
@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* =================================
  Media Queries
==================================== */

@media (max-width: 960px) {
  .try-btn {
    text-align: center;
  }
}

@media (max-width: 536px) {
  .barcode {
    height: 256px;
    width: 256px;
  }
}
</style>
