<template>
  <!-- dialog config -->
  <div>
    <v-overlay :value="overlay">
      <v-card color="primary" style="width: 400px">
        <v-card-text class="pt-3 white--text">
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>
    <!-- dialog alert -->
    <v-dialog v-model="dialogAlert" width="500" eager>
      <v-card>
        <v-card-title color="error">
          {{ $t('warning') }}
        </v-card-title>

        <v-card-text>
          {{ alertText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              dialogAlert = false
              dialogSendMessageLabel = true
            "
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSendMessageLabel" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('sendyourfirstmessage') }}
          </h3>
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" class="mr-3" @click="cancel">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" @click="agree">
            {{ $t('send') }}
          </v-btn>
        </v-card-actions>
        <v-divider />

        <v-form ref="form-send-message" lazy-validation>
          <v-card-text>
            <v-text-field
              v-model="input"
              class="mt-2"
              :rules="[v => (!!v && v.length > 10) || 'Phone Number is required! Min Length 10']"
              required
              type="number"
              outlined
              persistent-hint
              :hint="'Country Code + Phone Number ex : 62852xxxxxxx'"
              :label="`${$t('phoneNumber')}`"
            />

            <div v-if="numberAvability === false" class="ps-3 mb-2">
              <span>Invalid whatsapp number </span>
              <v-icon color="error">
                {{ icons.mdiCloseCircle }}
              </v-icon>
            </div>

            <v-textarea
              v-model="message"
              :rules="[v => !!v || 'Message is required!']"
              required
              :label="$t('messageTemplates.message')"
              outlined
              hide-details
            ></v-textarea>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiCloseCircle, mdiCheckCircle } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiCheckCircle,

        mdiCloseCircle,
      },
    }
  },
  data: () => ({
    dialogSendMessageLabel: false,
    numberAvability: null,
    resolve: null,
    reject: null,
    phoneNumber: '',
    message: '',
    userId: null,
    dialogAlert: false,
    alertText: '',
    overlay: false,
    timeout: null,
  }),
  computed: {
    input: {
      get() {
        return this.phoneNumber
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.phoneNumber = val
          this.onWriteValidate()
        }, 2000)
      },
    },
  },
  methods: {
    async checkValidWa() {
      // const phoneNumbers = {
      //   phone_numbers: [this.phoneNumber],
      // }

      const endpoint = `${process.env.VUE_APP_API_GENERAL}utils/exist/${this.userId}/${this.phoneNumber}`
      try {
        const res = await this.$axiosLimit.get(endpoint)
        //console.log(res, ' == res')

        return res && res.data.ok
      } catch (error) {
        return false
      }
    },
    async onWriteValidate() {
      const isValid = await this.checkValidWa()
      //console.log(isValid, ' == ISVALID')
      if (isValid) {
        this.numberAvability = true
      } else {
        this.numberAvability = false
      }
    },
    open(userId, phoneNumber = '', message = '') {
      this.dialogSendMessageLabel = true
      this.phoneNumber = phoneNumber
      this.message = message
      this.userId = userId
      this.dialogAlert = false
      this.alertText = ''
      this.overlay = false

      if (this.$refs['form-send-message']) {
        this.$refs['form-send-message'].reset()
      }

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    async agree() {
      const validate = this.$refs['form-send-message'].validate()
      if (validate) {
        this.overlay = true
        this.dialogSendMessageLabel = false
        const isValid = await this.checkValidWa()
        //console.log(isValid, ' == ISVALID')
        this.overlay = false
        if (isValid) {
          this.resolve({
            status: true,
            phoneNumber: this.phoneNumber,
            message: this.message,
          })
          this.dialogSendMessageLabel = false
        } else {
          this.alertText = 'Phone number is not valid'
          this.dialogAlert = true
        }
      }
    },
    cancel() {
      this.resolve({
        status: false,
        phoneNumber: '',
        message: '',
      })
      this.dialogSendMessageLabel = false
    },
  },
}
</script>
